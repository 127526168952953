<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal title="选择合同" :visible="visible" @cancel="cancel" :footer="null" width="600px">
      <a-input-search style="margin-bottom: 16px" placeholder="请输入合同名称/设计编号搜索" enter-button
        @search="onSearch"></a-input-search>

      <a-table :indentSize="0" bordered :data-source="list" :loading="loading" @change="onChange"
        :rowClassName="() => 'clickable'" :pagination="{
                          total,
                          current,
                          pageSize,
                          showTotal: (total) => `共 ${total} 条记录`,
                        }" :customRow="
                                  (record) => {
                                    return {
                                      on: {
                                        click: () => {
                                          onTableRowClick(record);
                                        },
                                      },
                                    };
                                  }
                                ">
        <a-table-column title="设计编号" data-index="code" width="120px">
        </a-table-column>

        <a-table-column title="合同名称" data-index="name"> </a-table-column>

        <a-table-column title="合同金额(万元)" data-index="contractAmount" align="right">
          <template slot-scope="contractAmount">
            <span style="color: #1890ff">
              <Money :money="contractAmount" />
            </span>
          </template>
        </a-table-column>

        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="contractRegisterStatus" :dictValue="text.contractSignStatus" iconType="badge" />
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
    };
  },

  computed: {
    ...mapState("contractSelector", [
      "loading",
      "current",
      "pageSize",
      "list",
      "total",
    ]),
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
  },

  methods: {
    ...mapMutations("contractSelector", [
      "setCurrent",
      "setPageSize",
      "setSearchValue",
    ]),
    ...mapActions("contractSelector", ["getList"]),

    onChange(pagination) {
      this.setCurrent(pagination.current);
      this.setPageSize(pagination.pageSize);
      this.getList();
    },

    onSearch(value) {
      this.setSearchValue(value);
      this.setCurrent(1);
      this.getList();
    },

    onTableRowClick(record) {
      console.log("record", record);
      if (record.status === "signed") {
        this.$emit("change", record);
        this.cancel();
      } else {
        this.$message.error("请选择已签订合同！");
      }
    },

    cancel() {
      this.visible = false;
    },
  },
};
</script>